@font-face {
  font-family: "MrsSaintDelafield";
  src: url(fonts/MrsSaintDelafield-Regular.ttf);
}

@font-face {
  font-family: "Photograph Signature";
  src: url(fonts/Photograph-Signature.ttf);
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
       url(fonts/Montserrat-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
       url(fonts/Montserrat-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
       url(fonts/Montserrat-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: "Amalfi Coast";
  src: local("Amalfi Coast"),
       url(fonts/Amalfi-Coast.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "EB Garamond";
  src: local("EB Garamond"),
       url(fonts/EBGaramond-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "EB Garamond";
  src: local("EB Garamond"),
       url(fonts/EBGaramond-Bold.ttf);
  font-weight: 700;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #FFFFFF;
  font-size: 13px;
  line-height: 13px;
}

button {
  font-family: Montserrat, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

*:focus-visible {
  outline: none;
  box-shadow: 1px 1px 5px rgba(1, 1, 0, .3);
}

*:focus:not(:focus-visible) {
  outline: none;
}

